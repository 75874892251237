import React, { useState } from "react";
import axios from 'axios';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'

const InviteUsers = () => {
  const { isAuthenticated } = useAuth0();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const inviteUsersAPI = async (e) => {
    e.preventDefault();
    const idToken = localStorage.getItem("idtoken");
    try {
      const requestData = {
        email: email
      };
      const response = await axios.post(
        // WARNING: This API is for DEMO ONLY!! Production will use IAM endpoints for user invitation and re-send invitation!
        "https://9lmasjvgml.execute-api.us-east-1.amazonaws.com/dev/onboard/invite",
        requestData,
        {
          headers: {
            "Content-Type": "application-json",
            "Authorization": `Bearer ${idToken}`
          },
        }
      );

      // console.log(response);

      setSubmitted(true);
    } catch (error) {
      setSubmitted(true);
    }
  };
  if ( !isAuthenticated ) {
    return (
      <div className="mt-5">
        <h3>Please login first</h3>
      </div>
    )
  }
  return submitted ? (
    <div className="mt-5">
      <h3>User invited!</h3>
    </div>
  ): (
    <div>
      <h1>Invite new user</h1>
      <form onSubmit={inviteUsersAPI}>
        <label>Email
          <input onChange={(e) => setEmail(e.target.value)} value={email}></input>
        </label>
        <button type="submit" className="btn btn-primary">Invite user</button>
      </form>
    </div>
  );
};

export default InviteUsers;
