import React, { useState } from "react";
import axios from 'axios';

const ExternalApi = () => {
  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [password, setPassword] = useState('');

  const createOrgAPI = async (e) => {
    try {
      e.preventDefault();
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        user: email,
        org_name: orgName,
        password: password
      };
      const response = await axios.post(
        "https://9lmasjvgml.execute-api.us-east-1.amazonaws.com/dev/onboard/start",
        requestData,
        {
          headers: {
            "Content-Type": "application-json",
          },
        }
      );

      setSubmitted(true);
    } catch (error) {
      setSubmitted(true);
    }
  };

  return submitted ? (
    <div className="mt-5">
      <h3>Check your email inbox!</h3>
    </div>
  ): (
    <div>
      <h1>Create Organization</h1>
      <form onSubmit={createOrgAPI}>
        <label>First Name
          <input onChange={(e) => setFirstName(e.target.value)} value={firstName}></input>
        </label>
        <br />
        <label>Last Name
          <input onChange={(e) => setLastName(e.target.value)} value={lastName}></input>
        </label>
        <br />
        <label>Email
          <input onChange={(e) => setEmail(e.target.value)} value={email}></input>
        </label>
        <br />
        <label>Org Name
          <input onChange={(e) => setOrgName(e.target.value)} value={orgName}></input>
        </label>
        <br />
        <label>Password
          <input onChange={(e) => setPassword(e.target.value)} value={password}></input>
        </label>
        <br />
        <button type="submit" className="btn btn-primary">Create Organization</button>
      </form>
    </div>
  );
};

export default ExternalApi;
