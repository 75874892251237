import { React, useEffect } from "react";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'

const logo = "https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png";

const Hero = () => {
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  const { loginWithRedirect, user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getTokens = async () => {

      try {
        const idToken = await getIdTokenClaims();
        const accessToken = await getAccessTokenSilently({
          audience: "https://auth.tgcloud-dev.com/api/v2"
        });
        localStorage.setItem("idtoken", idToken.__raw);
        localStorage.setItem("accesstoken", accessToken);
        console.log("IdToken!", idToken.__raw);
        console.log("AccessToken!", accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };
    if (isAuthenticated) {
      getTokens();
    }
  });
  console.log('======');
  console.log("=========", orgMatches);
  console.log("inviteMatches", inviteMatches);
  if (!isAuthenticated && orgMatches && !inviteMatches) {
    loginWithRedirect({
      organization: orgMatches[1],
      // invitation: inviteMatches[1],
    });
  } else if (!isAuthenticated && orgMatches && inviteMatches) {
    loginWithRedirect({
      organization: orgMatches[1],
      invitation: inviteMatches[1],
      additionalSignUpFields: [{
        name: "name",
        storage: "root"
      }],
    });
  }
  return isAuthenticated ? (
    <div className="text-center hero">
      <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
      <h1 className="mb-4">Org ID: {user.org_id}</h1>
      <p className="lead">
        Your email: {user.email}
      </p>
    </div>
  ) : (
    <div className="text-center hero">
      <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
      <h1 className="mb-4">Fake TGCloud Page</h1>
      <p className="lead">
        This is a fake TGCloud page!
      </p>
    </div>
  );
};

export default Hero;
