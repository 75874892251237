import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const idToken = localStorage.getItem("idtoken");
  const accessToken = localStorage.getItem("accesstoken");
  return (
    <div>
      <h1>Profile</h1>
      <p>
        IdToken: {idToken}
        <br />
      </p>
      <p>
        AccessToken: {accessToken}
        <br />
      </p>
    </div>
  );
};

export default Profile;
